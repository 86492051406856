// -------------------------------------------- REQUEST LIST START --------------------------------------------------------------
// ## Requests
//  1. CHECK SSL
// -------------------------------------------- REQUEST LIST ENDED --------------------------------------------------------------

// -------------------------------------------- INFO START --------------------------------------------------------------
// ## Info
//--start: Request başlangıç
//--end: Request bitiş

// ## RESPONSE CHECK ARRAY
// controls:
//   Api'den dönen mesajları kontrol eder.
//    hangi key'in kontrol edileceği "@services/Endpoints/index sayfasında {RESPONSE_CHECK_ENDPOINT} değerinde belirtilmelidir."
//   String array olarak belirtilmelidir.
// message:
//  text:String: Dönen cevabı kullanıcıya metin olarak dönmek için kullanılır.
//  status:Boolean: Dönen cevabı göstermek için kullanılır.
//  variant:String: işlem sonucunun varyantını belirtir.
//    variants:
//      success
//      danger
//      warning
//      info
// -------------------------------------------- INFO ENDED --------------------------------------------------------------

// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "@api";
import ResponseChecker from "@services/Middlewares/ResponseChecker";
import i18n from "@helpers/i18n.js";

import ResponseMessages from "../enums/responseMessages.js";
import { GENERATE_URL } from "../helpers/request.js";
import { CHECK_SSL_ENDPOINT } from "../Endpoints/Settings.js";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

//--start 1.Check SSL
// ## Check SSL Request
const responseCheckCheckSSL = () => [
  {
    controls: [ResponseMessages.OK],
    message: {
      text: i18n.t("request.success"),
      status: true,
      variant: "success",
    },
  }, // işlem başarılı olduğunda yapılan kontrol
  {
    controls: [],
    message: {
      text: i18n.t("login.usernameOrPasswordIsWrong"),
      status: true,
      variant: "danger",
    },
  },
];

export const REQUEST_CHECK_SSL = async (options) => {
  const apiResponse = await $api.get(GENERATE_URL(CHECK_SSL_ENDPOINT, options));
  const response = await ResponseChecker(responseCheckCheckSSL(), apiResponse);
  return response;
};

//--end CHECK SSL
