import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import form from "./modules/form";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    packageIdFromWebsite: "",
  },
  getters: {
    getPackageIdFromWebsite: (state) => state.packageIdFromWebsite,
  },
  mutations: {
    SET_PACKAGE_ID_FROM_WEBSITE(state, payload) {
      state.packageIdFromWebsite = payload;
    },
  },
  actions: {},
  modules: {
    app,
    form,
  },
});
