import { REQUEST_CREATE_COMPANY } from "@/services/Requests/Companies";
import { REQUEST_CHECK_SSL } from "@/services/Requests/Settings";

//router
import router from "@/router";

const state = {
  form: {
    email: "",
    domain: "",
    password: "",
    phoneNo: "",
    name: "",
  },
  activationLoadingStep: 1,
  isLoading: false,
};
const getters = {
  getForm: (state) => state.form,
};
const mutations = {
  UPDATE_FORM(state, payload) {
    state.form = { ...state.form, ...payload };
  },
  SET_FORM_LOADING(state, payload = true) {
    state.isLoading = payload;
  },
};

const actions = {
  async CREATE_COMPANY({ state, dispatch, commit, rootState }) {
    commit("SET_FORM_LOADING");
    REQUEST_CREATE_COMPANY(state.form);
    const response = { message: "OK" };
    if (response.message === "OK") {
      state.activationLoadingStep = 2;
      setInterval(async () => {
        const SSLisActivated = await dispatch("CHECK_SSL");
        if (SSLisActivated) {
          state.activationLoadingStep = 7;
          setTimeout(() => {
            location.href = `https://${state.form.domain}.heocademy.com/panel/#/sync-account?email=${state.form.email}&password=${state.form.password}&name=${state.form.name}&packageId=${rootState.packageIdFromWebsite}`;
          }, 1500);
        }
        if (state.activationLoadingStep < 6) state.activationLoadingStep += 1;
        else state.activationLoadingStep = 2;
      }, 10000);
    } else if (response.message === "ALREADYEXIST") {
      commit("SET_FORM_LOADING", false);
      dispatch("SET_ACTION_MODAL", {
        status: true,
        isLoading: false,
        message:
          "Ahh! Az önce oluşturmak istediğin akademi uzantısını veya e-mail adresi ile bir başka bir akademi hesabı oluşturulmuş görünüyor bu durum için üzgünüz. Farklı bir isim ile akademini oluşturabilir misin?",
        variant: "info",
        duration: 7000,
      });
      router.push({ name: "GetStarted" });
    } else {
      commit("SET_FORM_LOADING", false);
      dispatch("SET_ACTION_MODAL", {
        status: true,
        isLoading: false,
        message:
          "Akademinizi oluştururken bir hata ile karşılaştık, bu hata çözümü için uğraşıyoruz. Lütfen daha sonra tekrar deneyiniz..",
        variant: "danger",
        duration: 7000,
      });
      router.push({ name: "GetStarted" });
    }
  },
  async CHECK_SSL({ state }) {
    const responseSSL = await REQUEST_CHECK_SSL({
      query: {
        url: `https://${state.form.domain}.heocademy.com`,
      },
    });
    if (responseSSL.success) {
      const responseApiSSL = await REQUEST_CHECK_SSL({
        query: {
          url: `https://api-${state.form.domain}.heoxa.net`,
        },
      });
      return responseApiSSL.success || false;
    }
    return responseSSL.success || false;
  },
};

export default { state, getters, mutations, actions };
