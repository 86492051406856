<template>
  <button
    :disabled="disabled || loadingData || loading"
    :type="type"
    @click="(e) => $emit('click', e, SET_LOADING)"
    :class="`${variantComputed} ${blockComputed} ${sizeComputed}`"
  >
    <div v-if="loadingData || loading" class="loader-section">
      <span class="loader button"></span>
    </div>
    <slot></slot>
  </button>
</template>

<script>
export default {
  data() {
    return {
      loadingData: false,
    };
  },
  props: {
    type: {
      default: "button",
    },
    variant: {
      default: "",
    },
    size: {
      default: "",
    },
    block: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    variantComputed() {
      return this.variant ? `btn-${this.variant}` : "";
    },
    blockComputed() {
      return this.block ? `btn-block` : "";
    },
    sizeComputed() {
      return this.size !== "" ? `btn-${this.size}` : "";
    },
  },
  methods: {
    SET_LOADING(loading = true) {
      this.loadingData = loading;
    },
  },
};
</script>

<style></style>
