<template>
  <div class="o-primary-modal" v-if="value">
    <div
      class="o-primary-modal__content"
      :key="contentKey"
      :class="{
        'o-primary-modal__content--small': sm,
        'o-primary-modal__content--large': lg,
        'o-primary-modal__content--full': full,
      }"
      v-click-outside="DETECT_CLICK"
    >
      <div class="o-primary-modal__content__header" v-if="!hideHeader">
        <div class="header-title">{{ title }}</div>
        <div @click="CLOSE_MODAL" class="c-close-icon">
          <i class="icofont-close"></i>
        </div>
      </div>
      <div id="app-modal" class="o-primary-modal__content__body">
        <slot></slot>
      </div>
      <div v-if="!hideFooter" class="o-primary-modal__content__footer">
        <Button @click="CLOSE_MODAL" class="u-margin-right-small">{{
          cancelText
        }}</Button>
        <Button :loading="loading" @click="HANDLE_OK" variant="primary">{{
          okText
        }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
export default {
  components: { Button },
  props: {
    value: {
      default: false,
    },
    title: {
      default: "",
    },
    cancelText: {
      type: String,
      default: function () {
        return this.$t("cancel");
      },
    },
    sm: {
      default: false,
      type: Boolean,
    },
    lg: {
      default: false,
      type: Boolean,
    },
    full: {
      default: false,
      type: Boolean,
    },
    hideHeader: {
      default: false,
      type: Boolean,
    },
    hideFooter: {
      default: false,
      type: Boolean,
    },
    persistent: {
      default: false,
      type: Boolean,
    },
    okText: {
      type: String,
      default: function () {
        return this.$t("save");
      },
    },
  },
  data() {
    return {
      contentKey: 1,
      init: false,
      loading: false,
    };
  },
  watch: {
    value(data) {
      if (!data) {
        this.init = false;
        document.body.classList.remove("overflow-hidden");
      }
      // modal açıldığında body'deki scroll engellenir.
      else {
        setTimeout(() => {
          this.init = true;
        }, 100);
        document.body.classList.add("overflow-hidden");
      }
    },
  },
  methods: {
    // element dışına yapılan click'i detect etmek için kullanılır.
    DETECT_CLICK() {
      if (this.full) return;
      if (window.getSelection().toString().length > 0) return;
      if (this.persistent) return (this.contentKey += 1);
      this.CLOSE_MODAL();
    },
    // modal kapamak için kullanılır.
    CLOSE_MODAL() {
      if (!this.init) return;
      this.$emit("closing");
      this.$emit("input", false);
    },
    // ok handle
    HANDLE_OK(e) {
      this.$emit("ok", this.SET_LOADING, e);
    },
    SET_LOADING(data = false) {
      this.loading = data;
    },
  },
  beforeDestroy() {
    this.$emit("destroyed");
    // modal kapanırken body'deki scroll engelli kaldırılır.
    document.body.classList.remove("overflow-hidden");
  },
  mounted() {
    if (this.value) {
      setTimeout(() => {
        this.init = true;
      }, 100);
      document.body.classList.add("overflow-hidden");
    }
  },
};
</script>

<style></style>
