import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// locales
import tr from "@/locales/tr.json";
import en from "@/locales/en.json";

// messages list
const messages = {
  tr,
  en,
};
const i18n = new VueI18n({
  locale: "tr", // set locale
  messages, // set locale messages
});

export default i18n;
