import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@helpers/i18n";
Vue.use(VueRouter);
const routes = [
  {
    name: "app-start",
    path: "",
    redirect: "/get-started",
    component: () =>
      import(/* webpackChunkName: "app-start" */ "../layouts/default.vue"),
    children: [
      {
        name: "GetStarted",
        path: "/get-started",
        component: () =>
          import(
            /* webpackChunkName: "GetStarted" */ "../views/GetStarted.vue"
          ),
      },
      {
        name: "GetAccountInformation",
        path: "/get-account-information",
        component: () =>
          import(
            /* webpackChunkName: "GetAccountInformation" */ "../views/GetAccountInformation.vue"
          ),
      },
    ],
  },
  {
    name: "AppSignIn",
    path: "/sign-in",
    component: () =>
      import(/* webpackChunkName: "app-start" */ "../views/SignIn.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  document.title =
    i18n.t(`navigation.${to.name}`) + " | Heocademy Akademini Oluştur";
  next();
});
export default router;
