/**
 * @ {string} OK - İşlem başarılı olduğunda döner.
 * @param {string} NOTFOUND - Yapılan işlemde data bulunamaz ise döner.
 * @param {string} ERRORLOGIN - Giriş yapmak isteyen kullanıcı için hesap bilgileri yanlış olduğunda döner.
 * @param {string} LOGINERROR - Giriş yapmak isteyen kullanıcı için hesap bilgileri yanlış olduğunda döner.
 */
export default {
  OK: "OK",
  NOTFOUND: "NOTFOUND",
  ERRORLOGIN: "ERRORLOGIN",
  LOGINERROR: "LOGINERROR",
};
