<template>
  <div id="app">
    <router-view />
    <ActionModal />
  </div>
</template>

<script>
import ActionModal from "@/components/Base/ActionModal";
export default {
  components: { ActionModal },
  methods: {
    GET_LANG() {
      const navLang = navigator.language.split("-")[0] || "en";
      const lang = localStorage.getItem("lang") || navLang || "en";
      if (this.$i18n.availableLocales.includes(lang)) this.$i18n.locale = lang;
    },
  },
  async created() {
    // dil bilgisi alınır.
    this.GET_LANG();
  },
  mounted() {
    setTimeout(() => {
      const query = this.$route.query;
      if (query.packageId)
        this.$store.commit("SET_PACKAGE_ID_FROM_WEBSITE", query.packageId);
    }, 150);
  },
};
</script>

<style></style>
