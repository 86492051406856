// -------------------------------------------- REQUEST LIST START --------------------------------------------------------------
// ## Requests
//  1. CREATE_COMPANY
//  2. CHECK DDL
// -------------------------------------------- REQUEST LIST ENDED --------------------------------------------------------------

// -------------------------------------------- INFO START --------------------------------------------------------------
// ## Info
//--start: Request başlangıç
//--end: Request bitiş

// ## RESPONSE CHECK ARRAY
// controls:
//   Api'den dönen mesajları kontrol eder.
//    hangi key'in kontrol edileceği "@services/Endpoints/index sayfasında {RESPONSE_CHECK_ENDPOINT} değerinde belirtilmelidir."
//   String array olarak belirtilmelidir.
// message:
//  text:String: Dönen cevabı kullanıcıya metin olarak dönmek için kullanılır.
//  status:Boolean: Dönen cevabı göstermek için kullanılır.
//  variant:String: işlem sonucunun varyantını belirtir.
//    variants:
//      success
//      danger
//      warning
//      info
// -------------------------------------------- INFO ENDED --------------------------------------------------------------

// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "@api";
import ResponseChecker from "@services/Middlewares/ResponseChecker";
import i18n from "@helpers/i18n.js";

import ResponseMessages from "../enums/responseMessages.js";
import { GENERATE_URL } from "../helpers/request.js";
import {
  CREATE_COMPANY_ENDPOINT,
  CHECK_COMPANY_EMAIL,
} from "../Endpoints/Companies.js";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

//--start 1.Create Company
// ## Create Company Request
const responseCheckCompanies = () => [
  {
    controls: [ResponseMessages.OK],
    message: {
      text: i18n.t("request.success"),
      status: true,
      variant: "success",
    },
  }, // işlem başarılı olduğunda yapılan kontrol
  {
    controls: [
      ResponseMessages.NOTFOUND,
      ResponseMessages.ERRORLOGIN,
      ResponseMessages.LOGINERROR,
    ],
    message: {
      text: i18n.t("login.usernameOrPasswordIsWrong"),
      status: true,
      variant: "danger",
    },
  },
];

export const REQUEST_CREATE_COMPANY = async (form) => {
  const apiResponse = await $api.post(
    GENERATE_URL(CREATE_COMPANY_ENDPOINT),
    form
  );
  const response = await ResponseChecker(responseCheckCompanies(), apiResponse);
  return response;
};

//--end Create Company

//--start 2.Check Email
// ## Create Email Request
const responseCheckEmail = () => [
  {
    controls: [ResponseMessages.OK],
    message: {
      text: i18n.t("request.success"),
      status: true,
      variant: "success",
    },
  }, // işlem başarılı olduğunda yapılan kontrol
  {
    controls: [
      ResponseMessages.NOTFOUND,
      ResponseMessages.ERRORLOGIN,
      ResponseMessages.LOGINERROR,
    ],
    message: {
      text: i18n.t("login.usernameOrPasswordIsWrong"),
      status: true,
      variant: "danger",
    },
  },
];

export const REQUEST_CHECK_EMAIL = async (options) => {
  const apiResponse = await $api.get(
    GENERATE_URL(CHECK_COMPANY_EMAIL, options)
  );
  const response = await ResponseChecker(responseCheckEmail(), apiResponse);
  return response;
};

//--end Create Company
